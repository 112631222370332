export const BUCKET = 'images/vendors/'
export const BUCKET_ONG = 'images/ongs/'
export const VENDORS_PER_PAGE = 8;
export const TOTAL_VENDORS = 600;
export const CATEGORIES = [
  "Adult",
  "Automotive",
  "Babies Kids & Toys",
  "Beauty",
  "Books Movies & Music",
  "Dating",
  "Electronics IT&C",
  "Fashion",
  "Financial services",
  "Games",
  "Gifts & Flowers",
  "Groupons",
  "Health & Personal care",
  "Home & Garden",
  "Humanitarian",
  "Hypermarket & Groceries",
  "Insurance",
  "Jewelry",
  "Jobs",
  "Learning",
  "Office supplies",
  "Online Mall",
  "Others",
  "Pet supplies",
  "Pharma",
  "Real Estate",
  "Software",
  "Sports & outdoors",
  "Telecom",
  "Travel & Tourism",
  "Watches"
];

export const ONGs = [
  {
    value: '1234',
    label: 'Robert',
  },
  {
    value: '12345',
    label: 'ANdrei',
  }
];