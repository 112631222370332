import React from 'react';

import { Divider, Typography } from '@material-ui/core';

import useStyle from './style';

const Footer = () => {
    const classes = useStyle()

    return (
        <div className={classes.root}>
            <Divider />
            <Typography
                className={classes.company}
                variant="body1"
            >
                &copy; True 2019
            </Typography>
        </div>
    );
}

export default Footer;