import React, { useState, useContext } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
    SvgIcon,
} from '@material-ui/core';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ChatIcon from '@material-ui/icons/Chat';
import CallIcon from '@material-ui/icons/Call';
import DraftsIcon from '@material-ui/icons/Drafts';

import useStyle from './style';
import { AuthUserContext, FirebaseContext } from '../../../../contexts';
import { PAYMENT_SUCCESS } from '../../../../common/actionType';

// TODO:(ronesim):add error if the payment failed.
const ActionBar = (props) => {
    const { firebase } = useContext(FirebaseContext);
    const { auth, dispatch } = useContext(AuthUserContext);
    const { money, dispatchWallet } = props;

    const [account, setAccount] = useState({
        'firstName': auth.profile.firstName,
        'lastName': auth.profile.lastName,
        'cnp': auth.profile.cnp ? auth.profile.cnp : '',
        'bankName': auth.profile.account ? auth.profile.account.bank : '',
        'iban': auth.profile.account ? auth.profile.account.iban : ''
    });

    const [errors, setErrors] = useState({
        'firstName': null,
        'lastName': null,
        'cnp': null,
        'bankName': null,
        'iban': null
    });

    const [openPayment, setOpenPayment] = useState(false);
    const [payment, setPayment] = useState(false);

    const [openCommission, setOpenCommission] = useState(false);


    const totalMoney = money.personal.value + money.referrals.value;
    const classes = useStyle();

    const handleClickPaymentOpen = () => {
        setOpenPayment(true);
    };

    const handlePaymentClose = () => {
        setOpenPayment(false);
        setPayment(false);
    };

    const handleClickCommissionOpen = () => {
        setOpenCommission(true);
    };

    const handleClickCommissionClose = () => {
        setOpenCommission(false);
    };

    const handleChange = name => event => {
        setAccount({
            ...account,
            [name]: event.target.value
        });

        setErrors({
            ...errors,
            [name]: null,
        });
    };

    const doPayment = () => {
        if (!isNameValid('firstName') || !isNameValid('lastName') || !isNameValid('bankName')) return;

        if (!isCNPValid() || !isIBANValid()) return;

        var paymentRequest = firebase.functions().httpsCallable('paymentRequest');
        paymentRequest({
            uid: auth.user.uid,
            money: totalMoney,
            account: account
        }).then((result) => {
            console.log(result);
            dispatch({
                type: PAYMENT_SUCCESS,
                profile: {
                    ...auth.profile,
                    'cnp': account.cnp,
                    'account': {
                        'bank': account.bankName,
                        'iban': account.iban
                    }
                },
            });
            dispatchWallet({
                type: PAYMENT_SUCCESS,
                money: totalMoney
            })
            setPayment(true);
        }).catch(error => {
            setErrors({ ...errors, iban: 'Failed to process the payment. Please try again.' });
            console.log('Failed to process the payment', error);
        });
    }

    const isNameValid = (type) => {
        if (account[type].length === 0) {
            setErrors({ ...errors, [type]: 'Acest camp este obligatoriu.' });
            return false;
        }
        if (account[type].length > 50) {
            setErrors({ ...errors, [type]: 'Limita este de maxim 50 caractere' });
            return false;
        }
        setErrors({ ...errors, [type]: null });
        return true;
    }

    const isCNPValid = () => {
        let re = /^\d{1}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d| 5[0-2]|99)\d{4}$/,
            bigSum = 0,
            ctrlDigit = 0,
            control = '279146358279';
        if (re.test(account.cnp)) {
            for (var i = 0; i < 12; i++) {
                bigSum += account.cnp[i] * control[i];
            }
            ctrlDigit = bigSum % 11;
            if (ctrlDigit === 10) {
                ctrlDigit = 1;
            }

            if (ctrlDigit !== parseInt(account.cnp[12], 10)) {
                setErrors({ ...errors, cnp: 'CNP invalid.' });
                return false;
            } else {
                return true;
            }
        }
        setErrors({ ...errors, cnp: 'CNP invalid.' });
        return false;
    }

    const isIBANValid = () => {
        let re = /^[A-Z]{2}[0-9A-Z]*$/
        if (re.test(account.iban.toUpperCase())) {
            return true;
        }
        setErrors({ ...errors, iban: 'IBAN invalid.' });
        return false;
    }

    return (
        <React.Fragment>
            <Grid item xs={4} sm={3} md={2} lg={3} xl={3} className={classes.payment}>
                <Button
                    variant="contained"
                    color='primary'
                    onClick={handleClickPaymentOpen}
                >
                    Retrage
                </Button>
            </Grid>
            <Grid item xs={8} sm={5} md={5} lg={4} xl={4} className={classes.payment}>
                <Button
                    variant="outlined"
                    color='secondary'
                    onClick={handleClickCommissionOpen}
                >
                    Comision neinregistrat?
                </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={5} lg={5} xl={5} className={classes.info}>
                <InfoOutlinedIcon />
                <Typography variant="h6" component="h6" className={classes.infoText}>Comisioanele se aplica la pret fara TVA si transport.</Typography>
            </Grid>
            <Dialog open={openPayment} onClose={handlePaymentClose} aria-labelledby="payment-form">
                <DialogTitle id="payment-form">Retragere bani</DialogTitle>
                {!payment ?
                    <DialogContent>
                        <DialogContentText>
                            Suma minima pentru a putea efectua o retragere este de 50 RON.
                    </DialogContentText>
                        <DialogContentText>
                            <b>Status: {totalMoney >= 50.0 ? <span className={classes.statusOk}>{totalMoney + '/ 50'}</span> : <span className={classes.statusBad}>{totalMoney + '/ 50'}</span>}</b>
                        </DialogContentText>
                        <TextField
                            id="firstName"
                            label="Prenume"
                            type="text"
                            value={account.firstName}
                            onChange={handleChange('firstName')}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            required
                            error={errors.firstName ? true : false}
                            helperText={errors.firstName}
                        />
                        <TextField
                            id="lastName"
                            label="Nume"
                            type="text"
                            value={account.lastName}
                            onChange={handleChange('lastName')}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            required
                            error={errors.lastName ? true : false}
                            helperText={errors.lastName}
                        />
                        <TextField
                            id="cnp"
                            label="CNP"
                            type="text"
                            value={account.cnp}
                            onChange={handleChange('cnp')}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            required
                            error={errors.cnp ? true : false}
                            helperText={errors.cnp}
                        />
                        <TextField
                            id="bankName"
                            label="Numele Bancii"
                            type="text"
                            value={account.bankName}
                            onChange={handleChange('bankName')}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            required
                            error={errors.bankName ? true : false}
                            helperText={errors.bankName}
                        />
                        <TextField
                            id="iban"
                            label="IBAN"
                            type="text"
                            value={account.iban}
                            onChange={handleChange('iban')}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            required
                            error={errors.iban ? true : false}
                            helperText={errors.iban ? errors.iban : 'Introduceti codul IBAN fara spatii.'}
                        />
                    </DialogContent>
                    : <DialogContent> <DialogContentText>
                        Cererea de retragere s-a inregistrat cu succes. <br />
                        Urmareste e-mailul pentru a fi la curent cu statusul platii.
                    </DialogContentText></DialogContent>
                }
                {!payment ?
                    <DialogActions>
                        <Button onClick={handlePaymentClose} variant='outlined' color="primary">
                            Inchide
                        </Button>
                        <Button onClick={doPayment} variant='contained' color="primary" disabled={totalMoney < 50.0}>
                            Retrage
                        </Button>
                    </DialogActions>
                    :
                    <DialogActions>
                        <Button onClick={handlePaymentClose} variant='outlined' color="primary">
                            OK
                        </Button>
                    </DialogActions>
                }
            </Dialog>
            <Dialog open={openCommission} onClose={handleClickCommissionClose} aria-labelledby="commission-form">
                <DialogTitle id="commission-form">Ai un comision neinregistrat?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        In cazul in care un comision nu s-a inregistrat in decurs de 10 minute de la plasarea comenzii va rugam
                        sa folositi una dintre metodele de mai jos pentru a ne contacta.
                    </DialogContentText>
                    <List aria-label="options">
                        <ListItem>
                            <ListItemIcon>
                                <DraftsIcon />
                            </ListItemIcon>
                            <ListItemText primary="contact@true.deals" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CallIcon />
                            </ListItemIcon>
                            <ListItemText primary="+40 (741) 593 793" />
                        </ListItem>
                        <a href="https://www.facebook.com/recompensashoppingului/" rel="noopener noreferrer" target='_blank'>
                            <ListItem button >
                                <ListItemIcon>
                                    <SvgIcon viewBox="0 0 48 48">
                                        <path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z" /><path fill="#fff" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z" />
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText primary="True Romania" />
                            </ListItem>
                        </a>
                        <ListItem>
                            <ListItemIcon>
                                <ChatIcon />
                            </ListItemIcon>
                            <ListItemText primary="mesageria interna a site-ului (dreapta jos)" />
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickCommissionClose} variant='outlined' color="primary">
                        Ok
                        </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default ActionBar;