import React, { useState, useContext } from 'react';

import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
    Grid,
    IconButton,
    InputBase,
    Paper,
    Tooltip,
    Typography
} from '@material-ui/core';

import {
    Loyalty as LoyaltyIcon,
    Link as LinkIcon,
    FileCopyOutlined as FileCopyOutlinedIcon
} from '@material-ui/icons';

import { HomeLayout } from '../../layouts';

import useStyle from './style';
import { WalletContext } from '../../contexts';
import { WALLET } from '../../common/routes';

const Referrals = () => {
    const { wallet } = useContext(WalletContext);

    const [code] = useState(wallet.entry.referral.code);
    const [link] = useState("https://true.deals/r/" + wallet.entry.referral.code);
    const [codeCopied, setCodeCopied] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false);

    const [invited] = useState(wallet.entry.referral.invited);
    const classes = useStyle();

    const copyLink = () => {
        setLinkCopied(true);
        setCodeCopied(false);
        setTimeout(() => {
            setLinkCopied(false);
        }, 3000); 
    };

    const copyCode = () => {
        setCodeCopied(true);
        setLinkCopied(false);
        setTimeout(() => {
            setCodeCopied(false);
        }, 3000); 
    }
    return (
        <HomeLayout title="Invitatii">
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h1" component="h2" gutterBottom>
                            Ofera recompensa shopping-ului
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            1. Ajuta-ti prietenii sa nu mai piarda bani cumparand online trimitandu-le link-ul sau codul tau pentru inscriere.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            2. Persoanele inscrise cu link-ul sau codul tau primesc 10% mai multi bani inapoi.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            3. Noi iti oferim din partea noastra 10% din banii obtinuti de prietenii inscrisi cu link-ul sau codul tau. Pe VIATA!
                        </Typography> <br />
                        <Typography variant="h5" gutterBottom>
                            Distribuie link-ul sau codul tău de invitație:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={5} lg={5} xl={5}>
                        <Paper className={classes.linkRoot}>
                            <IconButton className={classes.iconButtonLink} disabled>
                                <LinkIcon />
                            </IconButton>
                            <InputBase
                                className={classes.input}
                                value={link}
                                inputProps={{
                                    readOnly: true,
                                }}
                            />
                            <CopyToClipboard text={link} onCopy={copyLink}>
                                <Tooltip title={linkCopied ? 'Copiat' : 'Copiaza link-ul'} aria-label="copiaza">
                                    <IconButton className={classes.iconButton}>
                                        <FileCopyOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </CopyToClipboard>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <Paper className={classes.linkRoot}>
                            <IconButton className={classes.iconButtonLink} disabled>
                                <LoyaltyIcon />
                            </IconButton>
                            <InputBase
                                className={classes.input}
                                value={code}
                                inputProps={{
                                    readOnly: true,
                                }}
                            />
                            <CopyToClipboard text={code} onCopy={copyCode}>
                                <Tooltip title={codeCopied ? 'Copiat' : 'Copiaza codul'} aria-label="copiaza">
                                    <IconButton className={classes.iconButton}>
                                        <FileCopyOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </CopyToClipboard>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.stats} variant="h5" gutterBottom>Total prieteni inscrisi: <span>{invited}</span></Typography> <br />
                        <Typography variant="h6" gutterBottom>*Banii castigati prin prieteni ii poti vedea in <Link to={WALLET}><i>Portofel</i></Link></Typography>
                    </Grid>
                </Grid>
            </div>
        </HomeLayout>
    )
    // return (
    //     <HomeLayout title="Invitatii">
    //         <div className={classes.root}>
    //             <ReferralsToolbar />
    //             <Grid container spacing={4}>
    //                 {referrals.length === 0 ?
    //                     <Grid item xs={12} className={classes.noInvite}>
    //                         <Typography variant="h5">You have not invite anyone yet! Please refer a user to increase your savings.
    //                             <Button color="primary" className={classes.button}>
    //                                 See details!
    //                             </Button>
    //                         </Typography>
    //                     </Grid>
    //                     :
    //                     <Grid item xs={12}>
    //                         <ReferralsTable
    //                             referrals={referrals}
    //                         />
    //                     </Grid>
    //                 }
    //             </Grid>
    //         </div>
    //     </HomeLayout>
    // );
}

export default Referrals;