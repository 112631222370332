import React from 'react';

import classNames from 'classnames';

import {
    Avatar,
    Divider,
    Grid,
    Paper,
    Tooltip,
    Typography
} from '@material-ui/core';

import {
    AttachMoney as AttachMoneyIcon,
    DoneOutline as DoneIcon,
    ErrorOutline as ErrorOutlineIcon,
    Group as GroupIcon,
    Person as PersonIcon,
    Update as UpdateIcon
} from '@material-ui/icons';

import useStyle from './style';


const InfoCard = (props) => {
    const classes = useStyle();

    const { status, commissionInfo, avatarBackgroundColor } = props;

    const displayIcon = (id) => {
        switch (id) {
            case 'pending':
                return (<UpdateIcon className={classes.icon} />)
            case 'approved':
                return (<DoneIcon className={classes.icon} />)
            case 'rejected':
                return (<ErrorOutlineIcon className={classes.icon} />)
            case 'paid':
                return (<AttachMoneyIcon className={classes.iconDark} />)
            default:
                return
        }
    }

    return (
        <Paper
            className={classNames(classes.root, { [classes.rootDark]: status.id === 'paid' })}
        >
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Avatar className={classNames(classes.avatar, avatarBackgroundColor)}>
                        {displayIcon(status.id)}
                    </Avatar>
                </Grid>
                <Grid item xs={7}>
                    <Typography
                        className={classNames(classes.title, { [classes.dark]: status.id === 'paid' })}
                        variant="h4"
                    >
                        {status.label}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.amount}>
                    <Typography
                        className={classNames(classes.value, { [classes.dark]: status.id === 'paid' })}
                        variant="h3"
                    >
                        {commissionInfo.personal.value + commissionInfo.referrals.value} RON
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={6} className={classes.stats}>
                    <Tooltip title="Generat de tine">
                        <PersonIcon className={classNames(classes.personIcon, { [classes.dark]: status.id === 'paid' })} />
                    </Tooltip>
                    <Typography
                        className={classNames(classes.personText, { [classes.dark]: status.id === 'paid' })}
                        variant="body1"
                    >
                        {commissionInfo.personal.count}
                    </Typography>
                </Grid>
                <Grid item xs={6} className={classes.stats}>
                    <Tooltip title="Generat de prieteni">
                        <GroupIcon className={classNames(classes.groupIcon, { [classes.dark]: status.id === 'paid' })} />
                    </Tooltip>
                    <Typography
                        className={classNames(classes.groupText, { [classes.dark]: status.id === 'paid' })}
                        variant="body1"
                    >
                        {commissionInfo.referrals.count}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default InfoCard;