import React, { useState, useContext } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    TextField,
    LinearProgress
} from '@material-ui/core';

import { UPDATE_WALLET } from '../../../common/actionType';
import { FirebaseContext, AuthUserContext, WalletContext } from '../../../contexts/';

const ReferralCode = (props) => {
    const { firebase } = useContext(FirebaseContext);
    const { auth } = useContext(AuthUserContext);
    const { dispatch } = useContext(WalletContext);

    const { isOpen, handleReferralCodeClose } = props;
    const [code, setCode] = useState('');
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(false);

    const handleChangeCode = (event) => {
        setCode(event.target.value);
        setError(null);
    };

    const handleCodeActivation = () => {
        if (code === '') {
            setError('Acest camp este obligatoriu.');
            return;
        }
        setLoading(true);

        var verifyCode = firebase.functions().httpsCallable('verifyCode');
        verifyCode({ code: code.toUpperCase() }).then((result) => {
            if (!result.data.userId) {
                setError('Cod promo invalid.');
                setLoading(false);
                return;
            }
            var updateReferralCode = firebase.functions().httpsCallable('updateReferralCode');
            updateReferralCode({user: auth.user.uid, referrer: result.data}).then((result) => {
                handleReferralCodeClose();
                dispatch({
                    type: UPDATE_WALLET,
                    rate: 60,
                    referral: {
                        'referred': true,
                        'referrer': {
                            'id': result.data.userId,
                            'type': result.data.userType
                        }
                    }
                });
            }).catch((error) => {
                setError('Va rugam incercati din nou!');
                setLoading(false);
                console.log(error);
            });
        }).catch((error) => {
            setError('Va rugam incercati din nou!');
            setLoading(false);
            console.log(error);
        });
    };

    return (
        <Dialog open={isOpen} onClose={handleReferralCodeClose} aria-labelledby="code">
            <DialogTitle id="code">Bine ai venit!</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Cu un cod bonus ai mai mult cashback. Ai unul? Introdu-l aici:
                </DialogContentText>
                <TextField
                    id="code"
                    label="Cod promo"
                    type="text"
                    value={code}
                    onChange={handleChangeCode}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required
                    error={error ? true : false}
                    helperText={error}
                />
                {loading ? <LinearProgress /> : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleReferralCodeClose} variant='outlined' color="primary">
                    Inchide
                        </Button>
                <Button onClick={handleCodeActivation} variant='contained' color="primary" disabled={loading}>
                    Activeaza
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ReferralCode;