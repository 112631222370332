import React, { useContext, useState, useEffect } from 'react';

import {
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';

import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons';

import { HomeLayout } from '../../layouts';
import { Tutorial, ReferralCode } from '../../layouts/components';
import { VendorCard, VendorsToolbar } from './components';

import useStyle from './style';

import { AuthUserContext, VendorContext, WalletContext } from '../../contexts';
import { VENDORS_PER_PAGE, TOTAL_VENDORS } from '../../common/config';
import { FETCH_UPDATE_PAGE, UPDATE_USER_STATUS } from '../../common/actionType';

const Vendors = () => {
  const { vendors, dispatch } = useContext(VendorContext);
  const { wallet } = useContext(WalletContext);
  const authContext = useContext(AuthUserContext);
  const auth = authContext.auth;
  const userDispatch = authContext.dispatch;
  const classes = useStyle();

  const [searchValue, setSearchValue] = useState('');
  const [categoriesSelected, setCategories] = useState([]);
  console.log(auth);

  const [isNewUser, setIsNewUser] = useState(auth.isNewUser);
  const [shouldAskForCode, setShouldAskForCode] = useState(isNewUser && !auth.hasReferralCode);
  const [isTutorialOpen, setTutorialOpen] = useState(isNewUser && !shouldAskForCode);

  console.log(shouldAskForCode);
  console.log(isTutorialOpen);
  useEffect(() => {
    setIsNewUser(auth.isNewUser);
    setShouldAskForCode(auth.isNewUser && !auth.hasReferralCode);
    setTutorialOpen(auth.isNewUser && !(auth.isNewUser && !auth.hasReferralCode));
  }, [auth]);

  const previousPage = () => {
    dispatch({
      type: FETCH_UPDATE_PAGE,
      page: vendors.page - 1
    });
  }

  const nextPage = () => {
    dispatch({
      type: FETCH_UPDATE_PAGE,
      page: vendors.page + 1
    });
  }

  const handleChangeSearchBar = event => {
    setSearchValue(event.target.value);
    dispatch({
      type: FETCH_UPDATE_PAGE,
      page: 0
    });
  }

  const handleChangeCategories = event => {
    setCategories(event.target.value);
    dispatch({
      type: FETCH_UPDATE_PAGE,
      page: 0
    });
  }

  const handleTutorialClose = () => {
    setTutorialOpen(false);
    userDispatch({
      type: UPDATE_USER_STATUS,
      isNewUser: false,
      hasCode: true
    });
  }

  const handleReferralCodeClose = () => {
    setShouldAskForCode(false);
    setTutorialOpen(true);
    userDispatch({
      type: UPDATE_USER_STATUS,
      isNewUser: true,
      hasCode: true
    });
  }

  const display_vendors = vendors.keys.filter(key => {
    return vendors.entries[key].name.toLowerCase().includes(searchValue.trim().toLowerCase());
  }).filter(key => {
    if (categoriesSelected.length > 0) {
      return categoriesSelected.filter(category => {
        return vendors.entries[key].categories.includes(category);
      }).length > 0
    }
    return true;
  });
  const total_vendors = Math.min(TOTAL_VENDORS, display_vendors.length)

  return (
    <HomeLayout title="Magazine" >
      <ReferralCode isOpen={shouldAskForCode} handleReferralCodeClose={handleReferralCodeClose} />
      <Tutorial isOpen={isTutorialOpen} isNewUser={isNewUser} handleTutorialClose={handleTutorialClose} />
      <div className={classes.root}>
        <VendorsToolbar
          searchValue={searchValue}
          handleChangeSearchBar={handleChangeSearchBar}
          categoriesSelected={categoriesSelected}
          handleChangeCategories={handleChangeCategories}
        />
        <Grid container spacing={4}>
          {/* {vendors.isLoaded && vendors.pages[vendors.page].length === 0 ? */}
          {vendors.keys.length === 0 ?
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
            :
            // vendors.isLoaded && vendors.pages[vendors.page].map(key => {
            display_vendors.slice(vendors.page * VENDORS_PER_PAGE, (vendors.page + 1) * VENDORS_PER_PAGE)
              .map(key => {
                return (
                  <Grid
                    item
                    key={key}
                    xs={12}
                    sm={4}
                    md={3}
                    lg={3}
                  >
                    <VendorCard key={key} vendor={vendors.entries[key]} userId={auth.user.uid} rate={wallet.entry.rate} />
                  </Grid>
                )
              })
          }
          <Grid item xs={12} className={classes.pagination}>
            <Typography variant="caption">{Math.min(vendors.page * VENDORS_PER_PAGE + 1, total_vendors) + '-' + Math.min(((vendors.page + 1) * VENDORS_PER_PAGE), total_vendors)} of {total_vendors}</Typography>
            <IconButton
              disabled={vendors.page === 0}
              onClick={() => previousPage()}
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              disabled={(vendors.page + 1) * VENDORS_PER_PAGE >= total_vendors}
              onClick={() => nextPage()}
            >
              <ChevronRightIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </HomeLayout>
  );
}

export default Vendors;