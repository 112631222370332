import React, { Fragment, useState } from 'react';

import classNames from 'classnames';

import { useTheme } from '@material-ui/styles';
import { useMediaQuery, Drawer } from '@material-ui/core';

import {
    Footer,
    Sidebar,
    Topbar
} from './components';

import { Tutorial } from '../components';

import useStyle from './style';

const Home = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isSidebarOpen, setSidebarOpen] = useState(!isMobile);
    const classes = useStyle();
    const { title, children } = props;

    const [isTutorialOpen, setTutorialOpen] = useState(false);

    const handleToggleOpen = () => {
        setSidebarOpen(!isSidebarOpen);
    }

    const handleSidebarClose = () => {
        setSidebarOpen(false);
    }

    const handleTutorialClose = () => {
        setTutorialOpen(false);
    }
    
    const handleTutorialOpen = () => {
        setTutorialOpen(true);
    }

    return (
        <Fragment>
            <Topbar
                className={classNames(classes.topbar, { [classes.topbarShift]: isSidebarOpen && !isMobile })}
                isSidebarOpen={isSidebarOpen}
                onToggleSidebar={handleToggleOpen}
                isMobile={isMobile}
                handleTutorialOpen={handleTutorialOpen}
                title={title}
            />
            <Drawer
                anchor="left"
                classes={{ paper: classes.drawerPaper }}
                onClose={handleSidebarClose}
                open={isSidebarOpen}
                variant={isMobile ? 'temporary' : 'persistent'}
            >
                <Sidebar className={classes.sidebar} />
            </Drawer>
            <main
                className={classNames(classes.content, {
                    [classes.contentShift]: isSidebarOpen && !isMobile
                })}
            >
                {children}
                <Footer />
            </main>
            <Tutorial isOpen={isTutorialOpen} isNewUser={false} handleTutorialClose={handleTutorialClose}/>
        </Fragment>
    );
}

export default Home;