import React, { useContext } from 'react';

import {
    Grid,
    Typography,
} from '@material-ui/core';

import { HomeLayout } from '../../layouts';

import { CommissionContext, WalletContext } from '../../contexts';

import { ActionBar, InfoCard, CommissionsTable } from './components';

import useStyle from './style';

const statusLabels = [
    { id: 'pending', label: 'Asteptare' },
    { id: 'approved', label: 'Aprobat' },
    { id: 'rejected', label: 'Respins' },
    { id: 'paid', label: 'Platit' }
]

const Wallet = () => {
    const { commissions } = useContext(CommissionContext);
    const { wallet, dispatch } = useContext(WalletContext);
    const classes = useStyle();
    console.log(wallet);
    return (
        <HomeLayout title="Portofel">
            <div className={classes.root}>
                <Grid container spacing={4}>
                    {statusLabels.map(status => {
                        return (<Grid
                            item
                            xs={12}
                            sm={6}
                            lg={3}
                            xl={3}
                            key={status.id}
                        >
                            <InfoCard status={status} commissionInfo={wallet.entry.commission[status.id]} avatarBackgroundColor={classes[status.id]} />
                        </Grid>
                        )
                    })}
                </Grid>
                <Grid container spacing={4} className={classes.actions}>
                    <ActionBar money={wallet.entry.commission['approved']} dispatchWallet={dispatch}/>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} className={classes.noCommissions}>
                        {commissions.entries.length === 0 ?
                            <Typography variant="h5">Inca nu ai cumparat ceva. Cumpara ca sa primesti o parte din bani inapoi.
                            </Typography>
                            :
                            <CommissionsTable
                                commissions={commissions}
                            />
                        }
                    </Grid>
                </Grid>
            </div>
        </HomeLayout>
    );
}

export default Wallet;