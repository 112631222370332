import React, { useContext } from 'react';

import {
    CircularProgress,
    Grid,
} from '@material-ui/core';

import { HomeLayout } from '../../layouts';
import { OfferCard } from './components';

import useStyle from './style';

import { AuthUserContext, OfferContext } from '../../contexts';

const Offers = () => {
    const { offers } = useContext(OfferContext);
    const { auth } = useContext(AuthUserContext);
    const classes = useStyle();

    const time_now =  Math.floor(Date.now() / 1000);
    return (
        <HomeLayout title="Oferte">
            <div className={classes.root}>
                <Grid container spacing={4}>
                    {offers.keys.length === 0 ?
                        <Grid item xs={12}>
                            <CircularProgress />
                        </Grid>
                        :
                        offers.keys.filter(key => {
                            return offers.entries[key].end > time_now && offers.entries[key].start <= time_now;
                        }).map(key => {
                            return (
                                <Grid
                                    item
                                    key={key}
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                >
                                    <OfferCard key={key} offer={offers.entries[key]} userId={auth.user.uid} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
        </HomeLayout>
    );
}

export default Offers;