import React, { Fragment, useContext } from 'react';

import classNames from 'classnames';

import {
    Button,
    IconButton,
    Typography,
    Toolbar,
    Tooltip
} from '@material-ui/core';

import {
    ChevronLeft as ChevronLeftIcon,
    Input as InputIcon,
    Help as HelpIcon,
    Menu as MenuIcon
} from '@material-ui/icons'

import {
    AuthUserContext,
    FirebaseContext
} from '../../../../contexts';

import useStyle from './style';

import { SIGN_OUT_SUCCESS } from '../../../../common/actionType';

const Topbar = (props) => {
    const { firebase } = useContext(FirebaseContext);
    const { dispatch } = useContext(AuthUserContext);

    const classes = useStyle();

    const { className, title, isSidebarOpen, onToggleSidebar, isMobile, handleTutorialOpen } = props;

    const handleSignOut = () => {
        firebase.auth().signOut().then(() => {
            dispatch({ type: SIGN_OUT_SUCCESS });
        })
    }

    return (
        <Fragment>
            <div className={classNames(classes.root, className)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        className={classes.menuButton}
                        onClick={onToggleSidebar}
                        varint="text"
                    >
                        {isSidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
                    </IconButton>
                    <Typography
                        className={classes.title}
                        variant="h4"
                    >
                        {title}
                    </Typography>
                    {isMobile ?
                        <Tooltip title="Cum functioneaza?">
                            <IconButton
                                className={classes.tutorialButton}
                                onClick={handleTutorialOpen}
                            >
                                <HelpIcon />
                            </IconButton>
                        </Tooltip>
                        :
                        <Button onClick={handleTutorialOpen} className={classes.tutorialButton} variant='outlined' color="primary">Cum functioneaza?</Button>
                    }
                    <Tooltip title="Iesire">
                        <IconButton
                            className={classes.signOutButton}
                            onClick={handleSignOut}
                        >
                            <InputIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </div>
        </Fragment>
    );
}

export default Topbar;