import React, { Fragment, useState } from 'react';

import classNames from 'classnames';

import {
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TablePagination
} from '@material-ui/core';

import useStyle from './style';

const convert_to_date = timestamp => {
    var toDate=new Date(timestamp * 1000).getDate();
    var toMonth=new Date(timestamp * 1000).getMonth()+1;
    var toYear=new Date(timestamp * 1000).getFullYear();
    return toDate + '-' + toMonth + '-' + toYear;
}

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    { id: 'createdAt', label: 'Data' },
    { id: 'amount', label: 'Suma' },
    { id: 'vendor', label: 'Magazin'},
    { id: 'status', label: 'Status' },
];

const status = {
    'pending': 'asteptare',
    'approved': 'aprobat',
    'rejected': 'respins',
    'paid': 'platit'
}

const EnhancedTableHead = (props) => {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const CommissionsTable = (props) => {
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdAt');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const classes = useStyle();

    const { commissions } = props;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, commissions.length - page * rowsPerPage);

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    return (
        <Fragment>
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={commissions.length}
                        />
                        <TableBody>
                            {stableSort(commissions.entries, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((commission) => {
                                    return (
                                        <TableRow key={commission.id}>
                                            <TableCell component="th" scope="row">{convert_to_date(commission.createdAt)}</TableCell>
                                            <TableCell>{commission.loto ?  commission.totalAmount : commission.amount}</TableCell>
                                            <TableCell>{commission.loto ? "TRUE" : commission.vendor}</TableCell>
                                            <TableCell>
                                                <Chip 
                                                    size="small"
                                                    className={classNames(classes.chip, classes[commission.status])}
                                                    label={status[commission.status]}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={commissions.entries.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </Fragment>
    );
}

export default CommissionsTable;
