import React, { useState } from 'react';

import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardHeader,
    CardMedia,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    IconButton,
    Tooltip,
    Typography,
    Grid,
    DialogActions,
    Link,
    Chip
} from '@material-ui/core';

import {
    Casino as CasinoIcon,
    Store as StoreIcon,
} from '@material-ui/icons'

import useStyle from './style';
import { BUCKET } from '../../../../common/config';

const OfferCard = (props) => {
    const classes = useStyle();
    const { offer, userId } = props;

    const [openShop, setOpenShop] = useState(false);

    const handleCloseShop = () => {
        setOpenShop(false);
    }

    const handleOpenShop = () => {
        setOpenShop(true);
    }

    // Compute the affiliate link.
    const provider = offer.providerId.split("-")[0];
    let affUrl;
    switch (provider) {
        case "2performant": affUrl = offer.affUrl + "&st=" + userId; break;
        case "admitad": affUrl = offer.affUrl + "&subid=" + userId; break;
        case "profitshare": affUrl = offer.affUrl + '/' + userId; break;
        default: affUrl = "true.deals"; break;
    }

    const generate_expire_in = (end) => {
        const time_now =  Math.floor(Date.now() / 1000);
        var diff = end - time_now;

        const days = Math.floor(diff / 86400);
        if (days > 1) {
            return "Expira in: " + days + " zile";
        }
        if (days === 1) {
            return "Expira in: 1 zi";
        }

        diff -= days * 86400;
        var hours = Math.floor(diff / 3600) % 24;
        if (hours > 1) {
            return "Expira in: " + hours + " ore";
        }
        if (hours === 1) {
            return "Expira in: 1 ora";
        }
        diff -= hours * 3600;
        var minutes = Math.floor(diff / 60) % 60;
        if (minutes > 1) {
            return "Expira in: " + minutes + " de minute";
        }
        if (minutes === 1) {
            return "Expira in: 1 minut";
        }
    }
    

    return (
        <React.Fragment>
            <Card>
                <CardHeader
                title={<Chip label={generate_expire_in(offer.end)} color={offer.end - Math.floor(Date.now() / 1000) < 604800 ? "secondary" : "primary"}></Chip>}
                />
                <CardActionArea onClick={handleOpenShop}>
                    <CardMedia
                        className={classes.media}
                        image={offer.banner ? offer.banner : BUCKET + offer.logo}
                        title={offer.providerName}
                        alt={offer.providerName}
                    />
                </CardActionArea>
                <CardContent className={classes.content}>
                    <Typography variant='h4' className={classes.title}>
                        {offer.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
                        {offer.description}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <Tooltip title="Cashback: garantat o parte din bani inapoi">
                        <IconButton href={affUrl} target="_blank" aria-label="store">
                            <StoreIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Tombola: sansa de a castiga intreaga valoare a comenzii tale">
                        <IconButton href={affUrl + "-1"} target="_blank" aria-label="store">
                            <CasinoIcon />
                        </IconButton>
                    </Tooltip>
                    <Button
                        size="small"
                        color="primary"
                        className={classes.learnMore}
                        onClick={handleOpenShop}
                    >
                        Detalii
                </Button>
                </CardActions>
            </Card>
            <Dialog
                open={openShop}
                onClose={handleCloseShop}
                aria-labelledby="shop-title"
                aria-describedby="shop-description"
            >
                <DialogTitle id="shop-title" className={classes.shopName}><span className={classes.shopName}>{offer.name}</span></DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            <DialogContentText id="shop-description">
                                Puteti opta pentru una dintre cele 2 variante:
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12}>
                            <DialogContentText id="shop-description">
                                <b>1. Cashback:</b> Primesti garantat pana la valoarea procentului afisat din totalul tranzactiei (fara TVA si taxe de transport).
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12}>
                            <DialogContentText id="shop-description">
                                <b>2. Tombola <span className={classes.newFeature}>(NOU)</span>:</b> Ai sansa, NU garantia de a castiga intreaga valoare a comenzii tale (inclusiv TVA, fara taxe de transport).
                                1 din 20 de tranzactii, in medie, este castigatoare. 
                                <Link href="https://docs.google.com/document/d/19Jj60dZrVOibN8N17X3_d38IgpOei0SNkogj5TJVmgg/edit?usp=sharing" target='_blank'>Regulament.</Link>
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                            <Button href={affUrl} onClick={handleCloseShop} target="_blank" variant='contained' color="primary">
                                <span className={classes.shopButton}>Vreau Cashback</span>
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                            <Button href={affUrl + "-1"} onClick={handleCloseShop} target="_blank" variant='contained' color="secondary">
                                <span className={classes.shopButton}>Vreau Tombola</span>
                            </Button>
                        </Grid>
                        <Grid item xs={12} className={classes.shopHint}>
                            <DialogContentText id="shop-description">
                                * Pentru a accesa direct "Cashback" sau "Tombola" puteti folosi butoanele <StoreIcon /> si <CasinoIcon /> din bara fiecarui magazin.
                            </DialogContentText>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseShop} variant='outlined' color='primary'>Inchide</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default OfferCard;