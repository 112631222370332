import React from 'react';

import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import VerticalLinearStepper from '../Stepper/Stepper';

const Tutorial = (props) => {
    const { isOpen, isNewUser, handleTutorialClose} = props;

    return (
        <Dialog open={isOpen} onClose={handleTutorialClose} aria-labelledby="tutorial">
            <DialogTitle id="tutorial">{isNewUser ? "Bine ai venit!" : "Cum functioneaza?"}</DialogTitle>
                <DialogContent>
                    <VerticalLinearStepper handleTutorialClose={handleTutorialClose} />
                </DialogContent>
        </Dialog>
    );
}

export default Tutorial;