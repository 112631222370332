import React, { useState, useRef, useEffect } from 'react';

import {
    Checkbox,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from '@material-ui/core';

import { Search as SearchIcon } from '@material-ui/icons'

import { CATEGORIES } from '../../../../common/config';

import useStyle from './style';

const VendorsToolbar = (props) => {
    const classes = useStyle();
    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);

    const { categoriesSelected, handleChangeCategories, searchValue, handleChangeSearchBar } = props;

    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <Grid container spacing={4}>
            <Grid
                item
                xs={12}
                sm={5}
                lg={4}
                xl={4}
            >
                <TextField
                    label="Cauta"
                    placeholder="Cauta magazin"
                    className={classes.searchField}
                    value={searchValue}
                    onChange={handleChangeSearchBar}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={5}
                lg={4}
                xl={4}
            >
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} htmlFor="categories">
                        Categorii
                    </InputLabel>
                    <Select
                        multiple
                        value={categoriesSelected}
                        onChange={handleChangeCategories}
                        input={<OutlinedInput labelWidth={labelWidth} name="Categorii" id="categories" />}
                        renderValue={selected => selected.join(', ')}
                    >
                        {CATEGORIES.map(category => (
                            <MenuItem key={category} value={category}>
                                <Checkbox checked={categoriesSelected.indexOf(category) > -1} />
                                <ListItemText primary={category} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default VendorsToolbar;