import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AuthUserContext, WalletContext } from './contexts';

import {
    Vendors,
    // Ong,
    Referrals,
    Wallet,
    Offers,
    SignUp,
    SignIn,
} from './views';


import { VENDORS, WALLET, OFFERS, LANDING, REFERRALS, REGISTER } from './common/routes';

const RoutesAuth = (props) => {
    const { wallet } = props;
    return (
        <Switch>
            <Redirect
                exact
                from={LANDING}
                to={VENDORS}
            />
            {/* <Route
                component={Dashboard}
                exact
                path={DASHBOARD}
            /> */}
            <Route
                component={Vendors}
                exact
                path={VENDORS}
            />
            <Route
                component={Offers}
                exact
                path={OFFERS}
            />
            <Route
                component={Wallet}
                exact
                path={WALLET}
            />
            {/* <Route 
                component={Ong}
                exact
                path={ONG}
            /> */}
            {wallet && wallet.type ?
                <Route
                    component={Referrals}
                    exact
                    path={REFERRALS}
                /> : null
            }
            <Redirect
                to={VENDORS}
            />
        </Switch>
    )
}

const RoutesNonAuth = () => {
    return (
        <Switch>
            <Route
                component={SignIn}
                exact
                path={LANDING}
            />
            <Route
                component={SignIn}
                exact
                path="/r/:code"
            />
            <Route
                component={SignUp}
                exact
                path={REGISTER}
            />
            {/* <Route
                component={Privacy}
                exact
                path={PRIVACY}
            /> */}
            <Redirect
                to={LANDING}
            />
        </Switch>
    );
}

const Routes = () => {
    const { auth } = useContext(AuthUserContext);
    const { wallet } = useContext(WalletContext);
    return (
        auth.user ? <RoutesAuth wallet={wallet.entry} /> : <RoutesNonAuth />
    )
}

export default Routes;
